<template>
  <div class="row align-items-center justify-content-between">
    <h1 class="w-auto m-0">{{ $t("sidebarEmployeesBtn") }}</h1>
    <div class="servicesPage__searchInput">
      <search-input
        v-model="search"
        :search-function="searchEmployee"
        @keyup.enter="searchEmployee"
        :placeholder="$t('findEmployee')"
      />
    </div>
  </div>
  <div
    class="row transactionsPage__tableTitles transactionsPage__tableTitles-company"
  >
    <div class="col-lg-6">{{ $t("accountOverviewPageEmail") }}</div>
    <div class="col-lg-3">{{ $t("CreditsBalance") }}</div>
  </div>
  <employees-company-table v-for="employee in employees" :employee="employee" />
  <div v-if="employees && employees.length === 10" class="w-max-content m-auto">
    <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
      >{{ $t("loadMore") }}
    </base-button>
  </div>
</template>

<script>
import SearchInput from "@/components/UI/inputs/SearchInput";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import VueMultiselect from "vue-multiselect";
import EmployeesCompanyTable from "@/components/elements/employees/EmployeesCompanyTable";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  name: "EmployeesCompanyPage",
  components: {
    BaseButton,
    EmployeesCompanyTable,
    ResetIcon,
    SearchInput,
    VueMultiselect,
  },
  data() {
    return {
      search: "",
      offset: 0,
      limit: 10,
    };
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    searchEmployee() {
      this.getEmployees(this.search);
    },
    loadMore() {
      this.limit = this.limit + 10;
      this.getEmployees(this.search);
    },
    async getEmployees(searchValue) {
      let search = searchValue ? searchValue : null;
      try {
        let employeeList = await this.$http
          .get("employees/company", {
            params: {
              search: search,
              offset: this.offset,
              limit: this.limit,
            },
          })
          .then(({ data }) => {
            return data.body;
          });
        this.$store.commit("SET_COMPANY_EMPLOYEES", employeeList);
      } catch (err) {
        if (err.response.data.status === 400) {
          this.toast.error(err.response.data.message);
        } else {
          this.toast.error(this.$t("NetworkError"));
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      employees: "COMPANY_EMPLOYEES",
    }),
  },
  watch: {
    employees: {
      deep: true,
      handler(value) {
        if (value === null) this.getEmployees();
      },
    },
  },
};
</script>

<style></style>
