<template>
  <div class="row align-items-center justify-content-between">
    <h1 class="w-auto m-0">{{ $t("sidebarEmployeesBtn") }}</h1>
    <div class="servicesPage__searchInput">
      <search-input
        v-model="search"
        :search-function="searchEmployee"
        @keyup.enter="searchEmployee"
        :placeholder="$t('findEmployee')"
      />
    </div>
  </div>
  <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
  <div class="servicesPage__filterWrapper">
    <vue-multiselect
      class="servicesPage__select me-10"
      :placeholder="$t('selectEmployeePosition')"
      :show-labels="false"
      :custom-label="customLabel"
      v-model="filter.position"
      :options="employeePositionsOptions"
    >
      <template #option="{ option }">
        {{ option.translations[0].name }}
      </template>
    </vue-multiselect>
    <vue-multiselect
      class="servicesPage__select"
      :placeholder="$t('selectEmployeeCompany')"
      :show-labels="false"
      :searchable="true"
      label="name"
      track-by="name"
      v-model="filter.company"
      :options="employeeCompanies"
    />
    <div class="col-1 experts__filterReset" @click="resetFilter()">
      <reset-icon />
      {{ $t("resetFilter") }}
    </div>
  </div>
  <div v-if="employees.length > 0">
    <div class="row justify-content-between transactionsPage__tableTitles">
      <div class="col-4">{{ $t("Employee") }}</div>
      <div class="col-3">{{ $t("accountOverviewPageEmail") }}</div>
      <div class="col-2">{{ $t("accountOverviewPagePosition") }}</div>
      <div class="col-2">{{ $t("Company") }}</div>
      <div class="col-1"></div>
    </div>
    <employee-cart
      v-for="employee in employees"
      :key="employee.user_id"
      :employee-information="employee"
    />
    <div
      v-if="employees && employees.length === 10"
      class="w-max-content m-auto"
    >
      <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
  </div>
  <div v-else class="mt-30">
    <h5>{{ $t("noFoundResult") }}</h5>
    <p class="mt-10">{{ $t("tryAnother") }}</p>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import EmployeeCart from "@/components/elements/card/EmployeeCart";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import VueMultiselect from "vue-multiselect";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  name: "EmployeesAdminPage",
  components: {
    BaseButton,
    ResetIcon,
    EmployeeCart,
    SearchInput,
    MainLayout,
    VueMultiselect,
  },
  data() {
    return {
      employees: {},
      employeePositionsOptions: [],
      employeeCompanies: [],
      search: "",
      filterValue: {
        position: [],
        company: [],
      },
      filter: {
        position: [],
        company: [],
        search: "",
      },
      limit: 10,
      offset: 0,
    };
  },
  mounted() {
    this.getEmployees();
    this.getEmployeePositions();
    this.getCompanies();
  },
  methods: {
    customLabel({ translations }) {
      return `${translations[0].name}`;
    },
    getEmployees(positionFilter, companyFilter, searchFilter) {
      let position = positionFilter ? positionFilter : null;
      let company = companyFilter ? companyFilter : null;
      let search = searchFilter ? searchFilter : null;
      this.$http
        .get("/employees", {
          params: {
            position: position,
            company: company,
            search: search,
            limit: this.limit,
            offset: this.offset,
          },
        })
        .then(({ data }) => {
          this.employees = data.body;
        });
    },
    getEmployeePositions() {
      this.$http.get("/employees-positions", {}).then(({ data }) => {
        this.employeePositionsOptions = data.body;
      });
    },
    getCompanies() {
      this.$http.get("/companies", {}).then(({ data }) => {
        this.employeeCompanies = data.body;
      });
    },
    resetFilter() {
      this.filter = {
        position: [],
        company: [],
      };
      this.search = "";
    },
    loadMore() {
      this.limit = this.limit + 10;
      this.getEmployees(
        this.filterValue.position,
        this.filterValue.company,
        this.search
      );
    },
    searchEmployee() {
      this.getEmployees(
        this.filterValue.position,
        this.filterValue.company,
        this.search
      );
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(value) {
        this.filterValue.company = value.company ? value.company.code : null;
        this.filterValue.position = value.position ? value.position.id : null;
        this.getEmployees(
          this.filterValue.position,
          this.filterValue.company,
          this.search
        );
      },
    },
  },
};
</script>

<style></style>
