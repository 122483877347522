<template>
  <main-layout>
    <employees-admin-page v-if="loginUser.role_id === 1" />
    <employees-company-page v-if="loginUser.role_id === 3" />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import EmployeesAdminPage from "@/components/pages-templates/employee/EmployeesAdminPage";
import { mapGetters } from "vuex";
import EmployeesCompanyPage from "@/components/pages-templates/employee/EmployeesCompanyPage";

export default {
  name: "EmployeesPage",
  components: { EmployeesCompanyPage, EmployeesAdminPage, MainLayout },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
