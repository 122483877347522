<template>
  <router-link :to="/employee/ + employeeInformation.code">
    <div
      class="row employeeCart"
      :class="{ 'blocked-user': employeeInformation.user.is_blocked }"
    >
      <div class="col-4 employeeCart__info">
        <avatar
          user-role="employees"
          :image-name="employeeInformation.image"
          :user-code="employeeInformation.code"
          size="100"
          height="60px"
          width="60px"
        />
        <div class="employeeCart__personalInfo">
          <div class="employeeCart__name">
            {{
              employeeInformation.first_name +
              " " +
              employeeInformation.last_name
            }}
          </div>
          <div class="employeeCart__code">{{ employeeInformation.code }}</div>
        </div>
      </div>
      <div class="col-3 employeeCart__mail">
        {{ employeeInformation.user.email }}
      </div>
      <div class="col-2 employeeCart__position">
        {{
          employeeInformation.position
            ? employeeInformation.position.translations[0].name
            : ""
        }}
      </div>
      <div class="col-2 employeeCart__company">
        {{ employeeInformation.company.name }}
      </div>
      <div class="col-1 text-end">
        <arrow height="16px" width="16px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Arrow from "@/components/UI/icons/arrows/Arrow";
import Avatar from "@/components/UI/images/Avatar";

export default {
  props: {
    employeeInformation: Object,
  },
  name: "EmployeeCart",
  components: { Avatar, Arrow },
};
</script>

<style></style>
